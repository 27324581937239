// TODO (12/6)
// - outline user submission flow
// - Add filter for top rated only
// - add more cities


import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Star, Filter } from "lucide-react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";

// Define custom icons
// const createCustomIcon = (color) =>
//   L.icon({
//     iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
//     shadowUrl:
//       "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: [1, -34],
//     shadowSize: [41, 41],
//   });

const ratingColors = {
  1: 'red',
  2: 'orange',
  3: 'yellow',
  4: 'green',
  5: 'blue'
};

// const redIcon = createCustomIcon('red');
// const orangeIcon = createCustomIcon('orange');
// const yellowIcon = createCustomIcon('yellow');
// const greenIcon = createCustomIcon('green');
// const blueIcon = createCustomIcon('blue');
// const grayIcon = createCustomIcon('grey');

const bestIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e92d2860c7f994692_2.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const fiveStarIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e1b624c573b368e65_3.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const fourStarIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e5926691315ca3a30_4.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const threeStarIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413ec43542155d5c3a5f_5.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const twoStarIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e7147c9149ce6ac4b_6.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const oneStarIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e57d161ebc30e0ff9_7.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const unratedIcon = L.icon({
  iconUrl: "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413ec11ba7f2aea32d5d_8.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [50, 50],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [41, 41],
});

const ColeslawLocation = ({
  id,
  name,
  distance,
  rating,
  lat,
  lng,
  onFocus,
  isSelected,
  isTopRated,
}) => (
  <div
    className={`mb-4 p-4 bg-white rounded-lg shadow cursor-pointer ${isSelected ? "ring-2 ring-blue-500" : ""}`}
    onClick={() => onFocus(lat, lng)}
  >
    <div className="flex justify-between items-start">
      <h3 className="text-lg font-semibold">{name}</h3>
      <img 
        src={
          isTopRated ? "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e92d2860c7f994692_2.png" :
          Math.round(rating) === 5 ? "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e1b624c573b368e65_3.png" :
          Math.round(rating) === 4 ? "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e5926691315ca3a30_4.png" :
          Math.round(rating) === 3 ? "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413ec43542155d5c3a5f_5.png" :
          Math.round(rating) === 2 ? "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e7147c9149ce6ac4b_6.png" :
          Math.round(rating) === 1 ? "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e57d161ebc30e0ff9_7.png" :
          "https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413ec11ba7f2aea32d5d_8.png"
        }
        alt={`Rating: ${rating || 'unrated'}`}
        className="w-12 h-12"
      />
    </div>
    <p className="text-sm text-gray-600">{distance.toFixed(1)} km away</p>
    <div className="flex items-center gap-2 my-2">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          className={
            i < Math.round(rating)
              ? "text-yellow-400 fill-current"
              : "text-gray-300"
          }
          size={16}
        />
      ))}
    </div>
  </div>
);

const CITIES = {
  Chicago: {
    name: "Chicago",
    coords: [41.8781, -87.6298]
  },
  "New York City": {
    name: "New York City",
    coords: [40.7128, -74.0060]
  },
  "Los Angeles": {
    name: "Los Angeles",
    coords: [34.0522, -118.2437]
  }
};

const ColeslawNearMe = () => {
  const [location, setLocation] = useState("");
  const mapRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [isMapFocused, setIsMapFocused] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [currentCity, setCurrentCity] = useState("Chicago");
  const markersRef = useRef({});
  const [activeTab, setActiveTab] = useState('rankings');
  const [mapBounds, setMapBounds] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(true);
  const [selectedRatings, setSelectedRatings] = useState(['top', 1, 2, 3, 4, 5, null]);

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      setLocationError('Geolocation is not supported by your browser');
      setIsLoadingLocation(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        setIsLoadingLocation(false);
      },
      (error) => {
        setLocationError('Unable to retrieve your location');
        setIsLoadingLocation(false);
      }
    );
  };

  const calculateDistance = (lat, lng) => {
    // Declare variables for reference coordinates
    let lat1, lng1;

    // If no user location, use city center as fallback
    if (!userLocation) {
      const cityCoords = CITIES[currentCity].coords;
      lat1 = cityCoords[0];
      lng1 = cityCoords[1];
    } else {
      lat1 = userLocation.lat;
      lng1 = userLocation.lng;
    }

    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat - lat1) * Math.PI) / 180;
    const dLon = ((lng - lng1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const ratedLocations = useMemo(() => {
    return locations.filter((loc) => loc.rating != null && !isNaN(loc.rating));
  }, [locations]);

  const percentileRanks = useMemo(() => {
    const sortedRatings = ratedLocations
      .map((loc) => loc.rating)
      .sort((a, b) => a - b);
    const ranks = {};
    sortedRatings.forEach((rating, index) => {
      ranks[rating] = (index / sortedRatings.length) * 100;
    });
    return ranks;
  }, [ratedLocations]);

  const getIconForRating = useCallback((rating, isTopRated = false) => {
    if (isTopRated) return bestIcon;
    if (rating == null || isNaN(rating)) return unratedIcon;
    const roundedRating = Math.round(rating);
    switch (roundedRating) {
      case 1: return oneStarIcon;
      case 2: return twoStarIcon;
      case 3: return threeStarIcon;
      case 4: return fourStarIcon;
      case 5: return fiveStarIcon;
      default: return unratedIcon;
    }
  }, []);

  // 1. Initialize map (runs once)
  useEffect(() => {
    mapRef.current = L.map("map").setView([41.8781, -87.6298], 11);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: '© OpenStreetMap contributors'
    }).addTo(mapRef.current);
  }, []);

  // 2. Fetch data (runs once)
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Attempting to fetch data...');
        const currentHost = window.location.origin;
        const response = await fetch(`${currentHost}/api/coleslaw-places`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Received data:', data);

        const formattedData = data.map((place) => ({
          id: place.id,
          name: place.name,
          distance: calculateDistance(place.latitude, place.longitude),
          rating: place.rating,
          lat: place.latitude,
          lng: place.longitude,
        }));
        
        console.log('Formatted data:', formattedData);
        setLocations(formattedData);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchData();
  }, []);

  // First, define topRated
  const topRated = useMemo(() => {
    if (!locations.length) return [];
    return [...locations]
      .sort((a, b) => (b.rating || 0) - (a.rating || 0))
      .slice(0, 3)
      .map(loc => loc.id);
  }, [locations]);

  // Then define filteredLocations using topRated
  const filteredLocations = useMemo(() => {
    if (!locations.length || !mapBounds) return [];
    
    return locations.filter(loc => {
      const inBounds = loc.lat >= mapBounds._southWest.lat &&
             loc.lat <= mapBounds._northEast.lat &&
             loc.lng >= mapBounds._southWest.lng &&
             loc.lng <= mapBounds._northEast.lng;
      
      const isTopRated = topRated.includes(loc.id);
      
      // If it's a top rated location, only show it if 'top' is selected
      if (isTopRated) {
        return inBounds && selectedRatings.includes('top');
      }
      
      // For non-top rated locations, check rating match
      const ratingMatch = selectedRatings.includes(loc.rating === null ? null : Math.round(loc.rating));
      return inBounds && ratingMatch;
    });
  }, [locations, mapBounds, selectedRatings, topRated]);

  // Then define sortedLocations
  const sortedLocations = useMemo(() => {
    return [...filteredLocations].sort((a, b) => {
      if (!a.rating && !b.rating) return 0;
      if (!a.rating) return 1;
      if (!b.rating) return -1;
      return b.rating - a.rating;
    });
  }, [filteredLocations]);

  const handleSearch = () => {
    // Implement search functionality here
    console.log("Searching for:", location);
  };

  const focusMarker = (lat, lng) => {
    if (mapRef.current) {
      if (lat === null && lng === null) {
        mapRef.current.setView([41.8781, -87.6298], 11);
        setIsMapFocused(false);
        setSelectedLocation(null);
        
        Object.values(markersRef.current).forEach((marker) => {
          marker.closeTooltip();
          marker.unbindTooltip();
          marker.bindTooltip(marker.tooltipContent, {
            direction: "top",
            offset: L.point(0, -20),
            opacity: 0.9,
          });
        });
      } else {
        mapRef.current.setView([lat, lng], 18);
        setIsMapFocused(true);
        setSelectedLocation({ lat, lng });

        Object.values(markersRef.current).forEach((marker) => {
          const markerLatLng = marker.getLatLng();
          if (markerLatLng.lat === lat && markerLatLng.lng === lng) {
            marker.unbindTooltip();
            marker
              .bindTooltip(marker.tooltipContent, {
                permanent: true,
                direction: "top",
                offset: L.point(0, -20),
                opacity: 0.9,
                className: "custom-tooltip selected-tooltip",
              })
              .openTooltip();
          } else {
            marker.closeTooltip();
            marker.unbindTooltip();
            marker.bindTooltip(marker.tooltipContent, {
              direction: "top",
              offset: L.point(0, -20),
              opacity: 0.9,
            });
          }
        });

        if (window.innerWidth < 1024) { // lg breakpoint
          setTimeout(() => {
            const selectedElement = document.querySelector('.ring-2.ring-blue-500');
            if (selectedElement) {
              selectedElement.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'nearest' 
              });
            }
          }, 100); // Small delay to ensure DOM update
        }
      }
    }
  };

  // Replace (not add) the existing averageRating memo
  const averageRating = useMemo(() => {
    const validRatings = filteredLocations.filter(loc => 
      loc.rating !== null && 
      !isNaN(loc.rating) &&
      loc.rating > 0
    );
    
    if (validRatings.length === 0) return 0;
    
    const sum = validRatings.reduce((acc, loc) => acc + Number(loc.rating), 0);
    return (sum / validRatings.length).toFixed(1);
  }, [filteredLocations]);

  // Add map move/zoom listener to update locations when viewport changes
  useEffect(() => {
    if (!mapRef.current) return;
    
    const map = mapRef.current;
    const handleViewportChange = () => {
      setMapBounds(map.getBounds());
    };
    
    map.on('moveend', handleViewportChange);
    map.on('zoomend', handleViewportChange);
    
    // Set initial bounds
    handleViewportChange();
    
    return () => {
      map.off('moveend', handleViewportChange);
      map.off('zoomend', handleViewportChange);
    };
  }, []);

  const handleCityChange = (cityName) => {
    const city = CITIES[cityName];
    setCurrentCity(city.name);
    if (mapRef.current) {
      mapRef.current.setView(city.coords, 11);
      setIsMapFocused(false);
      setSelectedLocation(null);
    }
  };

  // Add this useEffect after the initial map setup useEffect
  useEffect(() => {
    if (!mapRef.current || !filteredLocations.length) return;

    // Clear existing markers
    Object.values(markersRef.current).forEach(marker => {
      marker.remove();
    });
    markersRef.current = {};

    // Add new markers
    filteredLocations.forEach((loc) => {
      const isTopRated = topRated.includes(loc.id);
      const marker = L.marker([loc.lat, loc.lng], {
        icon: getIconForRating(loc.rating, isTopRated),
      });

      const tooltipContent = `
        <div class="font-semibold">${loc.name}</div>
        ${isTopRated ? '<div class="text-blue-600 font-medium">⭐ Top Rated</div>' : ''}
        <div>${loc.distance.toFixed(1)} km away</div>
        <div>${loc.rating ? "★".repeat(Math.round(loc.rating)) : 'Unrated'}</div>
      `;

      marker.tooltipContent = tooltipContent;
      
      marker.bindTooltip(tooltipContent, {
        permanent: selectedLocation && 
          selectedLocation.lat === loc.lat && 
          selectedLocation.lng === loc.lng,
        direction: "top",
        offset: L.point(0, -20),
        opacity: 0.9,
      });

      marker.on("click", () => {
        focusMarker(loc.lat, loc.lng);
      });

      marker.addTo(mapRef.current);
      markersRef.current[loc.id] = marker;
    });

    return () => {
      Object.values(markersRef.current).forEach(marker => {
        marker.remove();
      });
      markersRef.current = {};
    };
  }, [filteredLocations, selectedLocation, getIconForRating, topRated, focusMarker]);

  return (
    <div className="min-h-screen flex flex-col bg-custom-main">
      <header className="bg-custom-nav shadow">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="flex items-center">
              <img
                src="https://44340176.fs1.hubspotusercontent-na1.net/hubfs/44340176/Untitled%20design%20(8).png"
                alt="Coleslaw"
                className="h-10 w-10 mr-2"
              />
              <h1 className="text-3xl font-bold text-gray-900">SlawNearMe.com</h1>
            </div>
            <nav className="flex gap-4">
              {Object.keys(CITIES).map(cityName => (
                <button
                  key={cityName}
                  onClick={() => handleCityChange(cityName)}
                  className="text-gray-600 hover:text-gray-900 underline"
                >
                  {cityName}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-4 flex flex-col h-[calc(100vh-5rem)]">
        <div className="flex flex-col lg:flex-row gap-4 h-full">
          {/* Left sidebar */}
          <div className="w-full lg:w-1/3 flex flex-col order-2 lg:order-1 h-full">
            {/* Stats section - make it more compact */}
            <div className="bg-white p-4 rounded-lg shadow mb-4">
              <div className="flex justify-between items-end">
                {/* Left column - Basic stats */}
                <div className="space-y-3">
                  <h2 className="text-lg">
                    <span className="text-gray-600">City: </span>
                    <span className="font-semibold">{currentCity}</span>
                  </h2>
                  <div>
                    <h3 className="text-gray-700">Average Rating</h3>
                    <p className="text-2xl font-bold text-blue-500">
                      {averageRating > 0 ? `${averageRating} / 5` : 'No ratings yet'}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-gray-700">Total Locations</h3>
                    <p className="text-2xl font-bold text-blue-500">{filteredLocations.length}</p>
                  </div>
                </div>

                {/* Right column - Rating distribution */}
                <div style={{ width: '200px' }}>
                  {/* Chart container */}
                  <div className="relative h-32">
                    {/* Bars and labels */}
                    <div className="absolute bottom-6 w-full flex justify-between px-1" style={{ height: '80px' }}>
                      {[5, 4, 3, 2, 1, 'NR'].map((rating) => {
                        const count = rating === 'NR' 
                          ? filteredLocations.filter(loc => !loc.rating).length
                          : filteredLocations.filter(loc => Math.round(Number(loc.rating)) === rating).length;
                        const maxCount = Math.max(
                          ...filteredLocations.map(loc => 
                            !loc.rating ? filteredLocations.filter(l => !l.rating).length : 0
                          ),
                          ...filteredLocations.filter(loc => loc.rating).map(loc => 
                            filteredLocations.filter(l => Math.round(Number(l.rating)) === Math.round(Number(loc.rating))).length
                          )
                        );
                        const height = maxCount ? Math.min((count / maxCount) * 100, 100) : 0;
                        
                        return (
                          <div key={rating} className="w-5 h-full relative">
                            {count > 0 && (
                              <div 
                                className="absolute text-sm text-gray-600 w-full text-center"
                                style={{ bottom: `calc(${height}% + 4px)` }}
                              >
                                {count}
                              </div>
                            )}
                            <div 
                              className={`absolute bottom-0 w-full rounded-t-md ${
                                rating === 5 ? 'bg-blue-500' :
                                rating === 4 ? 'bg-green-500' :
                                rating === 3 ? 'bg-yellow-500' :
                                rating === 2 ? 'bg-orange-500' :
                                rating === 1 ? 'bg-red-500' : 'bg-gray-300'
                              }`}
                              style={{ height: `${height}%` }}
                            />
                          </div>
                        );
                      })}
                    </div>

                    {/* Axis line */}
                    <div className="absolute bottom-6 w-full h-px bg-gray-300" />

                    {/* Labels */}
                    <div className="absolute bottom-0 w-full flex justify-between px-1">
                      {[5, 4, 3, 2, 1, 'NR'].map((rating) => (
                        <div key={rating} className="text-sm">
                          {rating === 'NR' ? 'NR' : `${rating}★`}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Restaurant listings - adjust height to fill remaining space */}
            <div className="bg-gray-50 p-4 rounded-lg shadow flex-grow overflow-hidden">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Top Coleslaw Places</h2>
                <div className="relative">
                  <button
                    onClick={() => document.getElementById('rating-filter').classList.toggle('hidden')}
                    className="flex items-center gap-2 px-3 py-1.5 text-sm bg-white rounded-lg border hover:bg-gray-50"
                  >
                    <Filter size={16} />
                    Filter
                  </button>
                  <div
                    id="rating-filter"
                    className="hidden absolute right-0 top-full mt-2 w-48 bg-white rounded-lg shadow-lg border p-3 z-10"
                  >
                    <div className="space-y-2">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes('top')}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, 'top']
                                : prev.filter(r => r !== 'top')
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span className="flex items-center gap-1">
                          <img 
                            src="https://cdn.prod.website-files.com/652d109ee2cdc35ce4f4d4c4/6752413e92d2860c7f994692_2.png"
                            alt="Top Rated"
                            className="w-4 h-4"
                          />
                          Top Rated
                        </span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes(5)}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, 5]
                                : prev.filter(r => r !== 5)
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span className="flex items-center gap-1">
                          5 <Star className="text-yellow-400 fill-current" size={14} />
                        </span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes(4)}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, 4]
                                : prev.filter(r => r !== 4)
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span className="flex items-center gap-1">
                          4 <Star className="text-yellow-400 fill-current" size={14} />
                        </span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes(3)}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, 3]
                                : prev.filter(r => r !== 3)
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span className="flex items-center gap-1">
                          3 <Star className="text-yellow-400 fill-current" size={14} />
                        </span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes(2)}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, 2]
                                : prev.filter(r => r !== 2)
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span className="flex items-center gap-1">
                          2 <Star className="text-yellow-400 fill-current" size={14} />
                        </span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes(1)}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, 1]
                                : prev.filter(r => r !== 1)
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span className="flex items-center gap-1">
                          1 <Star className="text-yellow-400 fill-current" size={14} />
                        </span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedRatings.includes(null)}
                          onChange={(e) => {
                            setSelectedRatings(prev => 
                              e.target.checked 
                                ? [...prev, null]
                                : prev.filter(r => r !== null)
                            );
                          }}
                          className="rounded text-blue-500"
                        />
                        <span>Unrated</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overflow-y-auto h-[calc(100vh-20rem)]">
                {sortedLocations.map((loc) => (
                  <ColeslawLocation
                    key={loc.id}
                    {...loc}
                    onFocus={focusMarker}
                    isSelected={
                      selectedLocation &&
                      selectedLocation.lat === loc.lat &&
                      selectedLocation.lng === loc.lng
                    }
                    isTopRated={topRated.includes(loc.id)}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Map section */}
          <div className="w-full lg:w-2/3 flex flex-col order-1 lg:order-2 h-full">
            <div id="map" className="flex-grow rounded-lg shadow h-full"></div>
            {isMapFocused && (
              <button
                onClick={() => focusMarker(null, null)}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Reset Map View
              </button>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ColeslawNearMe;
